import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { widths, sizes, device } from "../../styles/sizes"
import { fontSizes, fonts } from "../../styles/typography"

export const StyledForm = styled.form`
  font-family: ${fonts.fontPrimary};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-basis: 1;
  color: ${colors.colorTextDark};
  width: 100%;
  margin: ${props => (props.isCentered ? `0px auto` : `0px`)};
`

export const FormRow = styled.div`
  margin-bottom: ${sizes.sizeXL};
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => (props.isCentered ? "center" : "flex-start")};

  &:last-of-type {
    margin-bottom: ${sizes.sizeXXL};
  }
`

export const FormField = styled.input`
  border: 2px solid transparent;
  border-radius: 8px;
  background: ${colors.colorContentLightest};
  color: ${colors.colorTextMedium};
  padding: ${sizes.sizeM} ${sizes.sizeL};
  font-size: ${fontSizes.fontS};
  margin-bottom: ${sizes.sizeM};
  font-family: ${fonts.fontPrimary};

  width: ${widths.width100};

  @media ${device.mobileL} { 
    width: ${widths.width100};
  }

  @media ${device.tablet} { 
    width: ${widths.width100};
  }

  @media ${device.laptop} { 
    width: ${widths.width50};
  }

  @media ${device.desktop} { 
    width: ${widths.width50};
  }

&::placeholder {
  font-family: ${fonts.fontPrimary};
}

  &:hover {
    border: 2px solid ${colors.colorTextMedium};
  }

  &:focus {
    color: ${colors.colorTextMedium};
    border: 2px solid ${colors.colorTextMedium};
    outline: none;
  }
`

export const FormTextarea = styled.textarea`
  border: 2px solid transparent;
  border-radius: 8px;
  background: ${colors.colorContentLightest};
  color: ${colors.colorTextMedium};
  padding: ${sizes.sizeM} ${sizes.sizeL};
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width: 75%;
  font-size: ${fontSizes.fontM};
  resize: vertical;
  overflow: auto;
  font-family: ${fonts.fontPrimary};

  &:hover {
    background-color: ${colors.colorContentLighter};
    border: 2px solid ${colors.colorTextMedium};
  }

  &:focus {
    color: ${colors.colorTextMedium};
    border: 2px solid ${colors.colorTextMedium};
    outline: none;
  }
`

export const FormLabel = styled.label`
  color: ${colors.colorTextMedium};
  font-size: ${fontSizes.fontS};
`

export const FormButton = styled.button`
  text-decoration: none;
  font: inherit;
  font-weight: 600;
  border-radius: 8px;
  padding: 1rem 2rem;
  margin: 0 auto;
  margin-right: ${sizes.sizeS};
  transition: all .3s cubic-bezier(0,.89,.44,1);
`

export const StyledFormButton = styled(FormButton)`
  margin-top: ${sizes.sizeL};
  border: none;
  background: linear-gradient(
    to right,
    ${colors.colorContentDark},
    ${colors.colorContentDarker}
  );
  color: ${colors.colorWhite};

  &:hover {
    background: ${colors.colorContentDark};
    box-shadow: 0 9px 21px 0 ${colors.colorContentLight};
    -moz-box-shadow: 0 9px 21px 0 ${colors.colorContentLight};
    -webkit-box-shadow: 0 9px 21px 0 ${colors.colorContentLight};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const StyledCheckboxGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${sizes.sizeM};
  margin-bottom: ${sizes.sizeL};

  label {
    position: relative;
    margin-right: ${sizes.sizeL};
  }

  label:before {
    content: "";
    padding: 1px;
    position: absolute;
    left: -${sizes.sizeXL};
    height: ${sizes.sizeM};
    width: ${sizes.sizeM};
    border: solid 1px ${colors.colorTextLighter};
    border-radius: 4px;
    z-index: -1;
  }

  input[type="checkbox"] {
    opacity: 0;
  }

  input[type="checkbox"]:checked + label:after {
    content: "✓";
    padding: 1px;
    position: absolute;
    top: -4px;
    left: -${sizes.sizeL};
    font-weight: 600;
    height: ${sizes.sizeM};
    width: ${sizes.sizeM};
    z-index: -1;
  }

  input[type="checkbox"]:checked + label:before {
    color: ${colors.colorContentDark};
    background-color: ${colors.colorContentLight};
  }
`
