import React, { useState } from "react"
import Layout from "../components/layout/Layout.js"
import { Row } from "../components/row/Row.js"
import {
  Title,
  Subtitle,
  SubHeading,
} from "../components/title/Title.js"
import Seo from "../components/seo"
import { Link } from "../components/link/Link.js"
import { Section } from "../components/section/Section.js"
import { Column50, Column100 } from "../components/column/Column"
import featuredImage from '../static/undraw_coffee_break_j3of.png'
import { ButtonContainer } from "../components/button/Button.js"
import {
  FormRow,
  FormField,
  FormTextarea,
  StyledForm,
  StyledFormButton,
} from "../components/form/style.js"
import Calendly from '../components/calendly/Calendly.js'


const FormButton = ({ buttonText, buttonLink }) => (
  <StyledFormButton to={buttonLink}>{buttonText}</StyledFormButton>
)

const ContactForm = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [service, setService] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = event => {
    event.preventDefault()

    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/25880547/b50693f7-b707-4742-b421-cf757fc326ca"

    // Example request JSON:
    var data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: email,
        },
        {
          name: "firstname",
          value: firstName,
        },
        {
          name: "lastname",
          value: lastName,
        },
        {
          name: "service",
          value: service,
        },
        {
          name: "message",
          value: message,
        },
      ],
      context: {
        // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: "https://schoolofcontent.net/contact/",
        pageName: "Contact page",
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text:
            "I agree to allow School of Content to store and process my personal data.",
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                "I agree to receive marketing communications from School of Content.",
            },
          ],
        },
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // alert(xhr.responseText) // Returns a 200 response if the submission is successful.
        window.location = "https://www.schoolofcontent.net/form-success/"
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        alert(xhr.responseText) //Returns a 404 error if the formGuid isn't found
      }
    }

    // Sends the request

    xhr.send(final_data)
  }

  return (
    <StyledForm
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      redirect="https://www.schoolofcontent.net/form-success/"
      onSubmit={handleSubmit}
      isCentered
    >
      <FormRow isCentered>
        <Column100 isFloating isCentered>
          <SubHeading subheading="Your contact details" />
          <FormField
            type="text"
            id="firstName"
            name="firstName"
            placeholder="First name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <FormField
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Last name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
          <FormField
            type="email"
            id="email"
            name="email"
            placeholder="Email*"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required="required"
          />
          <SubHeading subheading="Which service are you interested in?" />
          <FormField
            type="service"
            id="service"
            name="service"
            placeholder="Service"
            value={service}
            onChange={e => setService(e.target.value)}
          />
        </Column100>
      </FormRow>

      <FormRow isCentered>
        <Column100 isFloating isCentered>
          <SubHeading subheading="What can we do for you?*" />
          <FormTextarea
            placeholder="Add your message here"
            id="message"
            name="message"
            value={message}
            rows="10"
            cols="30"
            onChange={e => setMessage(e.target.value)}
            required="required"
          />
          <ButtonContainer isCentered>
            <FormButton
              buttonText="Send the message"
              buttonLink="/form-success/"
            />
          </ButtonContainer>
        </Column100>
      </FormRow>
    </StyledForm>
  )
}

function Contact({ children, isCentered, ...rest }) {
  return (
    <Layout>
      <Seo title="Contact" 
      description="Get in touch if you need help with your content strategy or if you'd like to outsource 
      your content marketing activities. We're here to help!"
      image={featuredImage}
      featuredImage={featuredImage}
      />
      <Section isCentered>
        <Title isPrimary isCentered title="How can we help you?" />
        <Row isCentered>
          <ContactForm />
        </Row>
      </Section>
    </Layout>
  )
}

export default Contact
